require('./bootstrap');
require('highcharts');
import $ from 'jquery';
window.$ = window.jQuery = $;
// require( 'datatables.net' );
$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });
    // if (jQuery) {
    //     alert("jQuery esta cargado");
    // } else {
    //     alert("jQuery no esta cargado");
    // }

    // $('#reporte_envios').DataTable();
    $('#reporte_envios').DataTable( {
        responsive: true,
        autoWidth:false,
        "language": {
            "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "Sin registros encontrados",
            "info": "Mostrando la página _PAGE_ de _PAGES_",
            "infoEmpty": "No records available",
            "infoFiltered": "(filtrado de _MAX_ registros totales)",
            "search": "Buscar",
            "paginate":{
                "next":"Siguiente",
                "previous":"Anterior"
            },


        },
        dom: 'Bfrtip',
        buttons: [
            {
                extend: 'excel',
                title: 'Reporte Detalle Envio',
                exportOptions: {
                    columns: "thead th:not(.noExport)"
                }
            },
            {
                extend: 'csv',


            }]
    } );
});
